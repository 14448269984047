import { forwardRef } from 'react'

import { cn } from '@canyon/ui/utils/cn'
import { VariantProps, cva } from 'class-variance-authority'

const inputVariants = cva(
  'placeholder:text-muted-foreground/50 flex h-8 w-full px-4 py-2 text-sm file:border-0 rounded-lg file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    defaultVariants: {
      variant: 'default',
    },
    variants: {
      variant: {
        default:
          'border-input ring-offset-background focus-visible:ring-ring border-0.5 bg-transparent focus-visible:ring-1 focus-visible:ring-offset-0',
        filled:
          'bg-background/40 placeholder:text-muted-foreground/50 border-0 focus-visible:ring-ring focus-visible:ring-1 focus-visible:ring-offset-0',
        ghost: 'bg-transparent p-0 h-fit',
        underlined: 'border-b-0.5 bg-transparent rounded-none border-border-accent p-0',
      },
    },
  }
)

export interface IInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  endSlot?: React.ReactElement
  startSlot?: React.ReactElement
  urlPrefix?: string
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ className, endSlot, startSlot, type, urlPrefix, variant, ...props }, ref) => {
    const StartSlot = startSlot
    const EndSlot = endSlot

    return (
      <div className="relative flex w-full items-center justify-center">
        <div className="relative flex w-full items-center">
          {urlPrefix && (
            <div className="flex h-8 items-center absolute left-0">
              <span className="text-sm text-muted-foreground whitespace-nowrap">{urlPrefix}</span>
            </div>
          )}
          <input
            className={cn(
              inputVariants({ className, variant }),
              startSlot ? 'pl-8' : '',
              endSlot ? 'pr-8' : ''
            )}
            ref={ref}
            style={{
              paddingLeft: urlPrefix ? `${urlPrefix.length * 0.4}rem` : '',
            }}
            type={type}
            {...props}
          />
          {StartSlot && <div className="pointer-events-none absolute left-2.5">{StartSlot}</div>}
          {endSlot && <div className="absolute right-2.5">{EndSlot}</div>}
        </div>
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
