const pageview = () => {
  window.fbq('track', 'PageView')
}

// Meta defined standard events, must be an event from the list
// https://developers.facebook.com/docs/meta-pixel/reference#standard-events
type TEventStandard = 'CompleteRegistration' | 'InitiateCheckout'
const eventStandard = (name: TEventStandard, options: Record<string, unknown> = {}) => {
  window.fbq('track', name, options)
}

const eventCustom = (name: string, options: Record<string, unknown> = {}) => {
  window.fbq('trackCustom', name, options)
}

export const fbq = {
  eventCustom,
  eventStandard,
  pageview,
}
