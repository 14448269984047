import type { TAlertVariant } from '@canyon/ui/Alert'

import { useEffect, useRef, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Alert, AlertDescription, AlertTitle } from '@canyon/ui/Alert'
import { Avatar, AvatarFallback, AvatarImage } from '@canyon/ui/Avatar'
import { Button } from '@canyon/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogScreen,
  DialogScreenProvider,
  InnerDialog,
  InnerDialogBody,
  InnerDialogClose,
  InnerDialogContent,
  InnerDialogFooter,
  InnerDialogHeader,
  InnerDialogTitle,
} from '@canyon/ui/Dialog'
import { IconText } from '@canyon/ui/IconText'
import { Input } from '@canyon/ui/Input'
import { Separator } from '@canyon/ui/Separator'
import { Slider } from '@canyon/ui/Slider'
import { Tooltip, TooltipContent, TooltipTrigger } from '@canyon/ui/Tooltip'
import { useToast } from '@canyon/ui/useToast'
import { cn } from '@canyon/ui/utils'
import { getCroppedImg } from '@canyon/utils'
import { Gear, User, Wallet } from '@phosphor-icons/react'
import { PencilSimple } from '@phosphor-icons/react'
import Cropper, { Area, type Point } from 'react-easy-crop'

import {
  AccountSettingsDialog_UpdateUserDocument,
  AccountSettingsDialog_UserDocument,
  AccountSettingsDialog_UserUploadProfilePictureDocument,
  UseCurrentUser_UsersDocument,
} from '@gql/graphql'

import { AdvisoryBadge } from '@/components/AdvisoryBadge'
import { ProBadge } from '@/components/ProBadge'
import { SupportLink } from '@/components/SupportLink'
import { UserForm } from '@/forms/UserForm'
import { useUserForm } from '@/forms/hooks/useUserForm'
import { useUserFormReset } from '@/forms/hooks/useUserFormReset'
import { useUpgradePlanDialog } from '@/hooks/contexts/useUpgradePlanDialog'
import { useBillingPlan } from '@/hooks/useBillingPlan'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import { useDelayedLoading } from '@/hooks/useDelayedLoading'
import { useFormAutoSave } from '@/hooks/useFormAutoSave'
import { uploadFile } from '@/utils/uploadFile'

export type TAccountSettingsDialogRoute = 'billing' | 'profile'

interface IAccountSettingsDialogProps {
  open: boolean
  route?: TAccountSettingsDialogRoute
  setOpen: (open: boolean) => void
}

export const AccountSettingsDialog = ({ open, route, setOpen }: IAccountSettingsDialogProps) => {
  const { user } = useCurrentUser()

  const screens = {
    billing: 1,
    profile: 0,
  }

  const [currentScreen, setCurrentScreen] = useState(screens.profile)

  const menuItems = [
    { icon: <User />, label: 'Profile' },
    { icon: <Wallet />, label: 'Billing' },
  ]

  useEffect(() => {
    if (!open) {
      setCurrentScreen(0)
    }
  }, [open])

  useEffect(() => {
    if (route) {
      setCurrentScreen(screens[route])
    }
  }, [route])

  return (
    <DialogScreenProvider currentScreenIndex={currentScreen}>
      <Dialog onOpenChange={setOpen} open={open}>
        <DialogContent
          className="h-[700px] max-w-[805px] p-0"
          title="Account Settings"
          titleIcon={<Gear />}
        >
          <div className="flex h-full flex-row">
            <div className="border-border-muted h-full w-1/4 border-r pr-4">
              <div className="flex flex-col justify-center gap-3">
                <div className="flex select-none flex-col justify-center text-sm">
                  <div className="overflow-hidden">
                    {user?.firstName} {user?.lastName}
                  </div>
                  <div className="text-muted-foreground overflow-hidden">
                    <Tooltip>
                      <TooltipTrigger asChild={true}>
                        <span>{user?.email}</span>
                      </TooltipTrigger>
                      <TooltipContent>
                        <span>{user?.email}</span>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="flex flex-col py-3">
                {menuItems.map(({ icon, label }, index) => (
                  <div
                    className={cn(
                      'hover:bg-accent/70 mt-2 cursor-pointer rounded-md px-3 py-2 text-sm',
                      currentScreen === index && 'bg-accent/20'
                    )}
                    key={label}
                    onClick={() => setCurrentScreen(index)}
                    role="menu"
                  >
                    <IconText className="text-sm" leftIcon={icon} role="menuitem">
                      {label}
                    </IconText>
                  </div>
                ))}
              </div>
            </div>
            <div className="h-full w-full overflow-y-auto px-10">
              <DialogScreen screenNumber={0}>
                <ProfileSettings />
              </DialogScreen>
              <DialogScreen screenNumber={1}>
                <BillingSettings />
              </DialogScreen>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </DialogScreenProvider>
  )
}

const SettingHeader = ({
  alert,
  subtitle,
  title,
}: {
  alert?: {
    description?: string
    title?: string
    variant?: TAlertVariant['variant']
  }
  subtitle?: string
  title: string
}) => {
  return (
    <div className="mb-4 flex flex-col gap-1">
      <div className="text-lg font-medium">{title}</div>
      <div className="text-muted-foreground text-sm">{subtitle}</div>
      {alert && (
        <Alert className="my-3" variant={alert.variant}>
          {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
          {alert.description && <AlertDescription>{alert.description}</AlertDescription>}
        </Alert>
      )}
      <Separator className="mt-3" />
    </div>
  )
}

const SettingContent = ({ children }: { children: React.ReactNode }) => {
  return <div className="mt-8 h-full">{children}</div>
}

const ProfilePicture = () => {
  const INITIAL_SCALE = 1.5

  const { user } = useCurrentUser()
  const profilePictureUrl = user?.profilePictureUrl
  const email = user?.email

  const profilePictureInputRef = useRef<HTMLInputElement>(null)

  const [isCropProfilePictureOpen, setIsCropProfilePictureOpen] = useState(false)
  const [selectedProfilePicture, setSelectedProfilePicture] = useState<File | null>(null)
  const [imgSrc, setImgSrc] = useState('')
  const [scale, setScale] = useState(INITIAL_SCALE)
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  })
  const [isSaving, setIsSaving] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [uploadProfilePicture] = useMutation(
    AccountSettingsDialog_UserUploadProfilePictureDocument,
    { refetchQueries: [UseCurrentUser_UsersDocument] }
  )

  const reset = () => {
    setImgSrc('')
    setSelectedProfilePicture(null)
    setScale(INITIAL_SCALE)
    setCroppedAreaPixels({ height: 0, width: 0, x: 0, y: 0 })
    setCrop({ x: 0, y: 0 })
    setHasError(false)
  }

  useEffect(() => {
    if (!isCropProfilePictureOpen) {
      reset()
    }
  }, [isCropProfilePictureOpen])

  function handleOnSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
        setSelectedProfilePicture(e.target.files?.[0] || null)
        setIsCropProfilePictureOpen(true)
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleOnCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const handleSave = async () => {
    setIsSaving(true)
    setHasError(false)

    try {
      if (!imgSrc) return

      const croppedImageBlob = await getCroppedImg(
        imgSrc,
        croppedAreaPixels,
        selectedProfilePicture?.type || 'image/jpeg'
      )

      if (!croppedImageBlob) return

      // Create a File from the blob
      const croppedImageFile = new File(
        [croppedImageBlob],
        selectedProfilePicture?.name || 'profile-picture.jpg',
        { type: selectedProfilePicture?.type || 'image/jpeg' }
      )

      await uploadFile({
        file: croppedImageFile,
        onComplete: async (response) => {
          await uploadProfilePicture({
            variables: {
              uploadSignedId: response.signed_id,
            },
          })
        },
        onError: () => {
          setHasError(true)
        },
      })

      setIsCropProfilePictureOpen(false)
    } catch (error) {
      console.error('Error cropping image:', error)
      setHasError(true)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div>
      <Input
        accept="image/png, image/jpeg"
        className="hidden"
        onChange={handleOnSelectFile}
        ref={profilePictureInputRef}
        type="file"
      />
      <Avatar
        className="border-primary group relative h-16 w-16 cursor-pointer border hover:border-amber-500"
        onClick={() => profilePictureInputRef.current?.click()}
      >
        <div className="absolute z-10 flex h-full w-full items-center justify-center rounded-full bg-black/50 opacity-0 transition-opacity duration-150 group-hover:opacity-100">
          <PencilSimple className="text-white" size={12} />
        </div>
        <AvatarImage alt="Profile picture" src={profilePictureUrl || ''} />
        <AvatarFallback gradientHashSource={email} />
      </Avatar>
      <InnerDialog onOpenChange={setIsCropProfilePictureOpen} open={isCropProfilePictureOpen}>
        <InnerDialogContent className="h-[700px] max-w-[805px]">
          <InnerDialogHeader>
            <InnerDialogTitle>Adjust Profile Picture</InnerDialogTitle>
          </InnerDialogHeader>
          <InnerDialogBody className="flex flex-col items-center gap-4">
            {hasError && (
              <Alert className="mb-4" variant="destructive">
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                  There was an error uploading your profile picture. Please try again.
                </AlertDescription>
              </Alert>
            )}
            {imgSrc && (
              <div className="flex flex-col items-center gap-4">
                <div className="relative mx-auto h-[400px] w-[400px]">
                  <Cropper
                    aspect={1}
                    classes={{
                      containerClassName: 'rounded-md',
                    }}
                    crop={crop}
                    image={imgSrc}
                    onCropChange={setCrop}
                    onCropComplete={handleOnCropComplete}
                    onZoomChange={setScale}
                    restrictPosition={false}
                    showGrid={false}
                    zoom={scale}
                  />
                </div>
                <div className="flex w-full flex-col gap-2">
                  <span className="text-sm font-medium">Scale</span>
                  <Slider
                    max={3}
                    min={1}
                    onValueChange={(value) => setScale(value[0])}
                    step={0.1}
                    value={[scale]}
                  />
                </div>
              </div>
            )}
          </InnerDialogBody>
          <InnerDialogFooter>
            <InnerDialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </InnerDialogClose>
            <Button disabled={isSaving} onClick={handleSave} variant="cta">
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </InnerDialogFooter>
        </InnerDialogContent>
      </InnerDialog>
    </div>
  )
}

const ProfileSettings = () => {
  const { errorToast } = useToast()
  const { isAdvisor, isAdvisorAdmin } = useCurrentUser()

  const { data, loading } = useQuery(AccountSettingsDialog_UserDocument)
  const [updateUser, { loading: updateUserLoading }] = useMutation(
    AccountSettingsDialog_UpdateUserDocument,
    { refetchQueries: [UseCurrentUser_UsersDocument] }
  )

  const user = data?.user

  const { form } = useUserForm()

  useUserFormReset({ form, user })

  const handleOnSubmit = async () => {
    const formValues = form.getValues()

    try {
      const { data } = await updateUser({
        variables: {
          attributes: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            linkedinUrl: formValues.linkedinUrl,
            location: formValues.location,
            phoneNumber: formValues.phoneNumber,
            website: formValues.website,
          },
        },
      })
    } catch (e) {
      console.error(e)

      errorToast()
    }
  }

  useFormAutoSave({
    defaultValues: user,
    form,
    onSubmit: handleOnSubmit,
  })

  const isUpdating = useDelayedLoading(updateUserLoading)

  return (
    <div>
      <SettingHeader
        alert={
          !isAdvisor && !isAdvisorAdmin
            ? {
                title: 'Changes to your profile will also apply to your resumes and cover letters.',
                variant: 'information',
              }
            : undefined
        }
        subtitle="Manage your personal details"
        title="Profile"
      />
      <SettingContent>
        <div className="bg-background-secondary border-border mb-4 flex w-full flex-col items-center justify-center gap-4 rounded-lg border py-4">
          <ProfilePicture />
          <span className="text-sm">Profile Picture</span>
        </div>
        <UserForm form={form} onSubmit={handleOnSubmit} />
        {isUpdating && (
          <div className="my-3 flex justify-end">
            <span className="text-muted-foreground text-sm">Saving...</span>
          </div>
        )}
      </SettingContent>
    </div>
  )
}

const BillingSettings = () => {
  const { isAdvisoryOrgAccount, isPaidPlan } = useCurrentUser()

  return (
    <div>
      <SettingHeader subtitle="Manage your billing details" title="Billing" />
      <SettingContent>
        <div className="flex flex-col justify-between">
          <BillingPlan plan={isAdvisoryOrgAccount ? 'advisory' : isPaidPlan ? 'pro' : 'free'} />
          <div className="text-muted-foreground mt-5 text-sm">
            Questions? Contact <SupportLink />.
          </div>
        </div>
      </SettingContent>
    </div>
  )
}

const BillingPlan = ({ plan }: { plan: 'advisory' | 'free' | 'pro' }) => {
  const { manageSubscription } = useBillingPlan()
  const { isLifetimePaidUser } = useCurrentUser()
  const upgradePlanDialog = useUpgradePlanDialog()

  const isAdvisor = plan === 'advisory'
  const isPro = plan === 'pro'
  const isFree = plan === 'free'

  const actionButton = isFree ? (
    <Button onClick={() => upgradePlanDialog.setOpen(true)} variant="cta">
      Upgrade Plan
    </Button>
  ) : isPro && !isLifetimePaidUser ? (
    <Button onClick={() => manageSubscription()}>Manage Subscription</Button>
  ) : null

  const tierBadge = isAdvisor ? (
    <AdvisoryBadge className="text-md" />
  ) : isPro ? (
    <ProBadge className="text-md" />
  ) : (
    <span className="text-md">Free</span>
  )

  return (
    <div className="flex h-full flex-col gap-3">
      <div className="font-medium">Current Plan</div>
      <div className="border-border-secondary bg-background-secondary flex rounded-lg border p-5">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row items-center gap-2">
            {tierBadge}{' '}
            {isLifetimePaidUser && <div className="text-muted-foreground text-sm">[Lifetime]</div>}
          </div>
          {actionButton}
        </div>
      </div>
    </div>
  )
}
