'use client'

import { useEffect, useState } from 'react'
import { createContext } from 'react'

import { CANYON_GOLD_WAITLIST_TYPEFORM_URL } from '@canyon/constants'
import {
  GOLD_FEATURES,
  LIMITED_FEATURES,
  PRICING_PLAN_AMOUNTS,
  UNLIMITED_FEATURES,
} from '@canyon/constants/pricing-plans'
import { Alert } from '@canyon/ui/Alert'
import { Badge } from '@canyon/ui/Badge'
import { Button } from '@canyon/ui/Button'
import { Card, CardContent } from '@canyon/ui/Card'
import { Dialog, DialogContent } from '@canyon/ui/Dialog'
import { Loader } from '@canyon/ui/Loader'
import {
  SwitchTabs,
  SwitchTabsContent,
  SwitchTabsList,
  SwitchTabsTrigger,
} from '@canyon/ui/SwitchTabs'
import { Tag } from '@canyon/ui/Tag'
import { cn } from '@canyon/ui/utils/cn'
import { ArrowCircleUp, CheckCircle } from '@phosphor-icons/react'
import { motion } from 'framer-motion'

import { PricingTable } from '@/components/PricingTable'
import { ProBadge } from '@/components/ProBadge'
import { useBillingPlan } from '@/hooks/useBillingPlan'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import { trackEvent } from '@/lib/utils/analytics'
import { TBillingPlanFrequency } from '@/types/pricing'

interface IUpgradePlanDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const EVENT_PREFIX = 'Pricing Plan Dialog'

const UpgradePlanDialogContext = createContext<
  { open: boolean; setOpen: (open: boolean) => void } | undefined
>(undefined)

export const UpgradePlanDialog = ({ open, setOpen }: IUpgradePlanDialogProps) => {
  const [pricingPlanTab, setPricingPlanTab] = useState<string>('quarterly')

  const { loading: currentUserIsLoading } = useCurrentUser()

  const handleBillingFrequencySwitch = (value: string) => {
    trackEvent(`${EVENT_PREFIX} - Toggled Billing Frequency`, { frequencyTo: value })
    setPricingPlanTab(value)
  }

  useEffect(() => {
    if (open) {
      trackEvent(`${EVENT_PREFIX} - Opened`)
    }
  }, [])

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      trackEvent(`${EVENT_PREFIX} - Closed`)
    }

    setOpen(open)
  }

  return (
    <UpgradePlanDialogContext.Provider value={{ open, setOpen }}>
      <Dialog onOpenChange={handleOpenChange} open={open}>
        <DialogContent
          className="h-full max-h-[940px] max-w-[1000px]"
          title="Upgrade Plan"
          titleIcon={<ArrowCircleUp />}
        >
          {currentUserIsLoading ? (
            <Loader />
          ) : (
            <SwitchTabs
              className="flex w-full flex-col items-center justify-center self-center p-4"
              defaultValue="quarterly"
              onValueChange={handleBillingFrequencySwitch}
              value={pricingPlanTab}
            >
              <SwitchTabsList>
                <SwitchTabsTrigger className="flex flex-row gap-2" value="quarterly">
                  Quarterly{' '}
                  <Badge className="px-1 py-0" variant="discount">
                    -14%
                  </Badge>
                </SwitchTabsTrigger>
                <SwitchTabsTrigger value="monthly">Monthly</SwitchTabsTrigger>
              </SwitchTabsList>
              <div className="mt-1 flex w-full flex-col gap-2">
                <Alert className="text-center">
                  <div>
                    <span className="text-md mr-2.5">💫</span>
                    Supercharge your job search - join over 2,500+ Canyon Pro users in landing more
                    interviews.
                  </div>
                </Alert>
                <SwitchTabsContent value="quarterly">
                  <PricingContent selectedPricing={pricingPlanTab} />
                </SwitchTabsContent>
                <SwitchTabsContent value="monthly">
                  <PricingContent selectedPricing={pricingPlanTab} />
                </SwitchTabsContent>
              </div>
            </SwitchTabs>
          )}
        </DialogContent>
      </Dialog>
    </UpgradePlanDialogContext.Provider>
  )
}

const PricingContent = ({ selectedPricing }: { selectedPricing: string }) => {
  return (
    <div className="space-y-6">
      <PricingPlanCards selectedPricing={selectedPricing} />
      <div className="space-y-3">
        <div className="text-center font-semibold">Feature Comparison</div>
        <PricingTable
          className="mt-4 max-h-[400px] overflow-y-auto"
          selectedPricing={selectedPricing}
        />
      </div>
    </div>
  )
}

const PricingPlanCards = ({ selectedPricing }: { selectedPricing: string }) => {
  const [isCreateSubscriptionLoading, setIsCreateSubscriptionLoading] = useState<boolean>(false)
  const { createSubscription } = useBillingPlan()
  const { isPaidPlan } = useCurrentUser()

  const handleUpgradePlan = () => {
    setIsCreateSubscriptionLoading(true)

    trackEvent(`${EVENT_PREFIX} - clicked Upgraded to Pro`, { frequency: selectedPricing })

    createSubscription({
      selectedBillingFrequency: selectedPricing as TBillingPlanFrequency,
    })
  }

  const handleJoinGoldWaitlist = () => {
    trackEvent(`${EVENT_PREFIX} - clicked Join Gold Waitlist`)

    window.open(CANYON_GOLD_WAITLIST_TYPEFORM_URL, '_blank')
  }

  return (
    <div className="flex w-full flex-row justify-center gap-3">
      <PricingPlanCard featuresList={LIMITED_FEATURES} pricePerMonth="0" title="Starter" />
      <PricingPlanCard
        button={
          isPaidPlan ? (
            <Button
              disabled={true}
              fullWidth={true}
              loading={isCreateSubscriptionLoading}
              size="lg"
              variant="ctaBlue"
            >
              Already on Pro Plan
            </Button>
          ) : (
            <Button
              fullWidth={true}
              loading={isCreateSubscriptionLoading}
              onClick={handleUpgradePlan}
              size="lg"
              variant="ctaBlue"
            >
              Upgrade to Pro
            </Button>
          )
        }
        featuresList={UNLIMITED_FEATURES}
        isHighlighted={true}
        isPro={true}
        pricePerMonth={PRICING_PLAN_AMOUNTS.pro[selectedPricing]}
        selectedPricing={selectedPricing}
        title={<ProBadge className="text-md" />}
      />
      <PricingPlanCard
        button={
          <Button
            className="bg-orange-500 text-orange-100 hover:bg-orange-600"
            fullWidth={true}
            onClick={handleJoinGoldWaitlist}
            size="lg"
          >
            Join Waitlist (20% off)
          </Button>
        }
        featuresList={GOLD_FEATURES}
        pricePerMonth={PRICING_PLAN_AMOUNTS.gold[selectedPricing]}
        selectedPricing={selectedPricing}
        title="Gold"
      />
    </div>
  )
}

interface IPricingPlanCardProps {
  button?: React.ReactNode
  featuresList?: string[]
  isHighlighted?: boolean
  isPro?: boolean
  pricePerMonth: null | string
  selectedPricing?: string
  title: React.ReactNode | string
}

const PricingPlanCard = ({
  button,
  featuresList,
  isHighlighted,
  isPro,
  pricePerMonth,
  selectedPricing,
  title,
}: IPricingPlanCardProps) => {
  const hasSelectedQuarterlyPricing = selectedPricing === 'quarterly'

  return (
    <Card className={cn('w-full', isHighlighted && 'border border-blue-500')}>
      <CardContent className="relative flex h-full flex-col gap-3 px-3 py-4">
        <div className="text-lg font-semibold">{title}</div>
        <div className={cn('text-2xl font-semibold', isPro && 'text-white')}>
          {pricePerMonth ? (
            <motion.span
              animate={{ opacity: 1, x: 0 }}
              className="inline"
              exit={{ opacity: 0, x: 20 }}
              initial={{ opacity: 0, x: -20 }}
              key={pricePerMonth}
              transition={{ duration: 0.5 }}
            >
              {`$${pricePerMonth}`}
              <span className="text-muted-foreground">{`/month${hasSelectedQuarterlyPricing ? '*' : ''}`}</span>
            </motion.span>
          ) : (
            <span className="">Coming Soon</span>
          )}
        </div>
        {featuresList && (
          <div className="text-muted-foreground flex flex-col gap-2 text-sm">
            {featuresList?.map((feature) => (
              <div className="flex flex-row items-center gap-2">
                <CheckCircle size={14} /> {feature}
              </div>
            ))}
          </div>
        )}
        <div className="my-2">{button}</div>
        {isPro && hasSelectedQuarterlyPricing && (
          <Badge className="absolute right-[16px] top-[16px]" variant="discount">
            Save $9
          </Badge>
        )}
      </CardContent>
    </Card>
  )
}
