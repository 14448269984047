import { Fragment, createContext, useState } from 'react'

import { AccountSettingsDialog } from '@/components/dialogs/AccountSettingsDialog'
import { UpgradePlanDialog } from '@/components/dialogs/UpgradePlanDialog'

type TGlobalDialogsContextType = {
  accountSettingsDialog: {
    isOpen: boolean
    route?: string
    setOpen: (open: boolean) => void
  }
  upgradePlanDialog: {
    isOpen: boolean
    setOpen: (open: boolean) => void
  }
}

export const GlobalDialogsContext = createContext<TGlobalDialogsContextType | undefined>(undefined)

/* WARNING: Be mindful of what dialogs are put here since they are global */
export const GlobalDialogsProvider = ({ children }: { children: React.ReactNode }) => {
  const [isUpgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false)
  const [isAccountSettingsDialogOpen, setAccountSettingsDialogOpen] = useState(false)

  const value = {
    accountSettingsDialog: {
      isOpen: isAccountSettingsDialogOpen,
      route: 'profile',
      setOpen: setAccountSettingsDialogOpen,
    },
    upgradePlanDialog: {
      isOpen: isUpgradePlanDialogOpen,
      setOpen: setUpgradePlanDialogOpen,
    },
  }

  const GlobalDialogs = () => (
    <Fragment>
      <UpgradePlanDialog open={isUpgradePlanDialogOpen} setOpen={setUpgradePlanDialogOpen} />
      <AccountSettingsDialog
        open={isAccountSettingsDialogOpen}
        setOpen={setAccountSettingsDialogOpen}
      />
    </Fragment>
  )

  return (
    <GlobalDialogsContext.Provider value={value}>
      {children}
      <GlobalDialogs />
    </GlobalDialogsContext.Provider>
  )
}
