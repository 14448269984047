import * as chrono from 'chrono-node'
import { differenceInDays, format } from 'date-fns'

export * from 'date-fns'

export const createDateWithTime = (date: string, time?: string): Date => {
  return new Date(`${date} ${time || '00:00:00'}`)
}

/**
 * @param options.excludeTZ - Whether to exclude the timezone from the date. Use this for date selectors that only show month/year.
 */
export const formatDate = (
  date?: Date | null | string | undefined,
  dateFormat?: string,
  options?: {
    excludeTZ?: boolean
    utc?: boolean
  }
): string => {
  if (!date) return ''

  if (typeof date === 'string') {
    // Timezones are messy with month/year only selectors, such as the ones used in the Resume Builder, since it uses 00:00:00 as the time.
    // This causes issues with the browser interpreting the timestamp and converting it to the user's local time which causes an offset.
    // For example: 04/2024 would be 03/2024 in the user's timezone.
    date = options?.excludeTZ ? new Date(date.replace('Z', '')) : new Date(date)
  }

  if (options?.utc) {
    date = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    )
  }

  return format(date, dateFormat ?? 'MMM yyyy')
}

export const formatDateTime = (datetime: Date | string) => {
  return new Date(datetime).toLocaleTimeString('en-US', {
    day: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

export const formatDateRange = (
  startDate: Date | null,
  endDate: Date | null,
  options?: {
    showPresent?: boolean // to handle current positions
  }
): string => {
  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    })
  }

  if (!startDate) return ''

  if (!endDate && options?.showPresent) {
    return `${formatDate(startDate)} - Present`
  }

  if (!endDate) return formatDate(startDate)

  return `${formatDate(startDate)} - ${formatDate(endDate)}`
}

export const parseDateTime = (str: Date | string) => {
  if (str instanceof Date) return str
  return chrono.parseDate(str)
}

export const getDateTimeLocal = (timestamp?: Date): string => {
  const d = timestamp ? new Date(timestamp) : new Date()
  if (d.toString() === 'Invalid Date') return ''
  return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
    .toISOString()
    .split(':')
    .slice(0, 2)
    .join(':')
}

export const isYearOnlyDate = (date: Date): boolean => {
  // Uses July 4th to determine if the date is a year only date
  return date.getMonth() === 6 && date.getDate() === 4
}

export const setYearOnlyDateFromString = (date: string): Date => {
  // Use July 4th to determine if the date is a year only date
  return new Date(parseInt(date, 10), 6, 4)
}

export const formatStartAndEndDates = ({
  endDate,
  endDateIsYearOnly,
  present,
  startDate,
  startDateIsYearOnly,
}: {
  endDate?: Date | null | string | undefined
  endDateIsYearOnly?: boolean | null | undefined
  present?: boolean | null | undefined
  startDate?: Date | null | string | undefined
  startDateIsYearOnly?: boolean | null | undefined
}): string => {
  if (!startDate && !endDate) return ''

  let startDateFormatted = startDate
    ? formatDate(startDate, undefined, { excludeTZ: true })
    : undefined
  let endDateFormatted = endDate ? formatDate(endDate, undefined, { excludeTZ: true }) : undefined

  if (startDate && startDateIsYearOnly) {
    startDateFormatted = formatDate(startDate, 'yyyy', { excludeTZ: true })
  }

  if (endDate && endDateIsYearOnly) {
    endDateFormatted = formatDate(endDate, 'yyyy', { excludeTZ: true })
  }

  if (startDate && present) {
    return `${startDateFormatted} - Present`
  }

  if (!startDateFormatted && endDateFormatted) {
    return endDateFormatted
  }

  if (!endDateFormatted && startDateFormatted) {
    return startDateFormatted
  }

  // If the start and end dates are in the same year, only show the month and year.
  if (
    (startDate && new Date(startDate).getFullYear()) ===
      (endDate && new Date(endDate).getFullYear()) &&
    !startDateIsYearOnly
  ) {
    return `${formatDate(startDate, 'MMM', { excludeTZ: true })} - ${endDateFormatted}`
  }

  return `${startDateFormatted} - ${endDateFormatted}`
}

export const timeAgoDays = (date: Date): string => {
  const days = differenceInDays(new Date(), date)
  return days === 0 ? 'Today' : `${days}d`
}
