import { KeyboardEvent } from 'react'

import { ReadonlyURLSearchParams } from 'next/navigation'

export * from './date-fns'
export * from './image-utils'
export * from './meta-pixel'

export const restrictKeyInput = (e: KeyboardEvent<HTMLInputElement>, regex?: RegExp) => {
  const key = e.key
  const allowedKeys = [
    'Backspace',
    'ArrowRight',
    'ArrowLeft',
    'ArrowUp',
    'ArrowDown',
    'Delete',
    'Tab',
    'Enter',
  ]

  const specialKeys = ['a', 'c', 'v', 'x', 'z']

  if (regex && !key.match(regex) && !allowedKeys.includes(key)) {
    if (!(e.metaKey || e.ctrlKey) || !specialKeys.includes(key)) {
      e.preventDefault()
    }
  }
}

export const formatCurrencyThousands = (num: null | number | undefined): null | string => {
  if (!num) return null

  if (num >= 1000) {
    return '$' + (num / 1000).toFixed(0) + 'K'
  } else {
    return '$' + num
  }
}

export const formatMoney = ({
  amount,
  currencyCode = 'USD',
  locale = 'en-US',
}: {
  amount: number
  currencyCode?: string
  locale?: string
}) => {
  const formatter = new Intl.NumberFormat(locale, {
    currency: currencyCode,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
  })

  return formatter.format(amount)
}

export const formatMinMaxSalary = (min?: null | number, max?: null | number): string => {
  if (!min && !max) return ''

  if (min && !max) return `${formatMoney({ amount: min })}`
  if (!min && max) return `${formatMoney({ amount: max })}`

  return `${formatMoney({ amount: min ?? 0 })} - ${formatMoney({ amount: max ?? 0 })}`
}

export const extractListItemEl = (htmlStr: string): string[] => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlStr, 'text/html')
  const nodes = Array.from(doc.getElementsByTagName('li'))
  return nodes.map((node) => node.outerHTML)
}

export const stringsToOptions = (strings: string[]) =>
  strings.map((string) => ({ label: string, value: string }))

export const objectToQueryString = (
  obj: ReadonlyURLSearchParams | Record<string, string>
): string => {
  return new URLSearchParams(obj).toString()
}

export const isValidHttpUrl = (url_string: string): boolean => {
  let url

  try {
    url = new URL(url_string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const trimUrl = (url: string): string => {
  if (!url) return url

  const noProtocol = url.replace(/(^\w+:|^)\/\//, '')
  const noWWW = noProtocol.replace(/^www\./, '')

  return noWWW.replace(/\/$/, '')
}

export const getInitials = (name: string): string => {
  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('')
}

export const hashCode = (str: string) => {
  let hash = 0

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32-bit integer
  }

  return hash
}

export const generateColors = (text: string) => {
  const hash = Math.abs(hashCode(text))
  const hue1 = hash % 360
  const hue2 = (hue1 + 120) % 360
  const hue3 = (hue2 + 120) % 360

  const saturation = 80
  const lightness = 60

  return [
    `hsl(${hue1}, ${saturation}%, ${lightness}%)`,
    `hsl(${hue2}, ${saturation}%, ${lightness}%)`,
    `hsl(${hue3}, ${saturation}%, ${lightness}%)`,
  ]
}

const isHTML = (str) =>
  !(str || '')
    // replace html tag with content
    .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
    // remove remaining self closing tags
    .replace(/(<([^>]+)>)/gi, '')
    // remove extra space at start and end
    .trim()

export const stripHtmlTags = (html: null | string | undefined) => {
  if (!html) return html

  if (isHTML(html)) {
    return html.replace(/<[^>]*>?/gm, '')
  } else {
    return html
  }
}
