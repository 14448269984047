import { forwardRef } from 'react'

import { RiLoader5Line } from '@remixicon/react'
import { type VariantProps, cva } from 'class-variance-authority'

import { cn } from '../utils'

const buttonVariants = cva(
  'flex items-center justify-center rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 transition-all duration-300',
  {
    defaultVariants: {
      size: 'default',
      variant: 'default',
    },
    variants: {
      size: {
        default: 'h-8 px-2.5 py-1.5',
        icon: 'h-fit p-0.5',
        lg: 'h-10 px-8 text-base',
        sm: 'py-1 px-2 text-sm h-7',
        xs: 'py-0.5 px-2 text-xs h-6',
      },
      variant: {
        action: 'text-stone-400 hover:bg-stone-400/20 hover:text-stone-100 rounded-md shadow-none',
        ai: 'border-spectrum-indigo-subtle bg-spectrum-indigo-bold text-white hover:bg-spectrum-indigo-bold/80',
        cta: 'bg-amber-500 text-black shadow hover:bg-amber-500/90',
        ctaBlue: 'bg-blue-500 text-white shadow hover:bg-blue-500/90',
        default: 'bg-primary text-primary-foreground shadow hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        ghost: 'hover:bg-white/10',
        iconButton: 'hover:bg-white/20 hover:text-accent-foreground shadow-none rounded-md',
        link: 'text-amber-500 w-fit p-0 underline-offset-4 hover:underline shadow-none',
        mutedLink: 'text-muted-foreground w-fit p-0 underline-offset-4 hover:underline',
        outline:
          'border-0.5 border-input bg-transparent shadow-sm hover:bg-accent/20 hover:text-accent-foreground',
        primary: 'bg-stone-200 text-stone-900 hover:bg-stone-200/90',
        primaryInverted: 'bg-stone-900 text-stone-200 hover:bg-stone-900/90',
        secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
      },
    },
  }
)

const spinnerVariant = cva('animate-spin', {
  defaultVariants: {
    size: 'default',
  },
  variants: {
    size: {
      default: 'w-4',
      icon: 'w-4',
      lg: 'w-5',
      sm: 'w-3',
      xs: 'w-2.5',
    },
  },
})

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  fullWidth?: boolean
  isIcon?: boolean
  leftIcon?: React.ReactNode
  loading?: boolean
  rightIcon?: React.ReactNode
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      className,
      disabled,
      fullWidth,
      isIcon,
      leftIcon,
      loading,
      rightIcon,
      size,
      variant,
      ...props
    },
    ref
  ) => {
    const buttonSize = isIcon ? 'icon' : size
    const buttonVariant = isIcon ? 'iconButton' : variant

    return (
      <button
        className={cn(
          buttonVariants({ className, size: buttonSize, variant: buttonVariant }),
          fullWidth && 'w-full'
        )}
        disabled={disabled || loading}
        ref={ref}
        type="button"
        {...props}
      >
        {leftIcon && !loading ? <span className="mr-1.5">{leftIcon}</span> : null}
        {loading ? (
          <RiLoader5Line
            className={cn(spinnerVariant({ size: buttonSize }), props.children ? 'mr-1' : null)}
          />
        ) : null}
        {props.children}
        {rightIcon && !loading ? <span className="ml-1.5">{rightIcon}</span> : null}
      </button>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
