export const CHROME_EXTENSION_URL =
  'https://chromewebstore.google.com/detail/canyon-track-apply-to-job/npekhmlmillbfcbohangleomoblkckih'

export const SITE_URL = 'https://www.usecanyon.com'
export const ADVISORY_WAITLIST_TYPEFORM_URL = 'https://f3ximpv2jr1.typeform.com/to/BVW0PkNi'
export const CANYON_GOLD_WAITLIST_TYPEFORM_URL = 'https://f3ximpv2jr1.typeform.com/to/ZD9xqsSL'
export const TERMS_OF_SERVICE_URL = 'https://www.usecanyon.com/terms-of-service'
export const PRIVACY_POLICY_URL = 'https://www.usecanyon.com/privacy-policy'

export const AUTH_URLS = {
  FORGOT_PASSWORD: '/auth/forgot-password',
  SIGN_IN: '/auth/signin',
  SIGN_UP: '/auth/signup',
}
